//@import "bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/leaflet-geosearch/dist/geosearch.css";
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@runette/leaflet-fullscreen/dist/leaflet.fullscreen.css";
@import "font-awesome/css/font-awesome.min.css";
@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "sweetalert2/dist/sweetalert2.min.css";
@import "leaflet/dist/leaflet.css";
@import "leaflet.markercluster/dist/MarkerCluster.Default.css";
@import "leaflet-draw/dist/leaflet.draw-src.css";
@import "leaflet-draw/dist/leaflet.draw.css";
@import "../node_modules/@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
@import url("https://fonts.googleapis.com/css?family=Roboto");

/********************************************************************************************************************/
// ANCHOR VARIABLES
/********************************************************************************************************************/

$radius: 0.25rem;

// Marcadores
$marker-red: rgba(211, 47, 47, 1);
$marker-green: rgba(67, 160, 71, 1);
$marker-yellow: rgba(255, 235, 59, 1);
$marker-orange: #854000;
$marker-limeGreen: limeGreen;
$marker-blue: rgba(25, 118, 210, 1);
$marker-purple: rgba(171, 71, 188, 1);
$marker-turquoise: rgb(42, 192, 202);
$marker-cadetblue: #5f9ea0;
$marker-gray: rgba(158, 158, 158, 1);

// Clúster
$marker-cluster-lightRed: rgba(255, 102, 89, 0.75);
$marker-cluster-lightOrange: rgba(156, 107, 62, 0.75);
$marker-cluster-lightTurquoise: rgba(6, 184, 175, 0.75);
$marker-cluster-lightGreen: rgba(102, 187, 106, 0.75);
$marker-cluster-lightPurple: rgba(206, 147, 216, 0.75);
$marker-cluster-lightGray: rgba(189, 189, 189, 0.75);
$marker-cluster-lightWhite: rgba(255, 255, 255, 0.75);
$marker-cluster-lightYellow: rgba(255, 244, 144, 0.75);
$marker-cluster-lightBlue: rgba(187, 222, 251, 0.75);
$marker-cluster-lightCadetblue: rgba(181, 241, 243, 0.75);
$marker-cluster-gateway: rgb(124, 223, 218);

// Gradientes
$marker-gradient-green: #43a047;
$marker-gradient-turquoise: #06b8af;
$marker-gradient-cadetblue: #5f9ea0;
$marker-gradient-purple: #ab47bc;
$marker-gradient-red: #d32f2f;
$marker-gradient-blue: #1976d2;
$marker-gradient-yellow: #ffae00;
$marker-gradient-brown: #854000;
$marker-gradient-orange: #ff6600;
$marker-gradient-gray: gray;

/********************************************************************************************************************/
// ANCHOR ESTILOS GENERALES
/********************************************************************************************************************/

body {
  font-family: "Roboto", sans-serif !important;
  position: relative;
  font-display: swap;
  padding: 0px !important;
  background-image: url("./assets/img/app_background.png");
  background-repeat: repeat;
}

.help-active {
  * {
    cursor: help !important;
  }
}

a:hover {
  text-decoration: none;
}

.row {
  margin: 0rem !important;
}

:focus {
  outline: inherit !important;
}

.content-wrapper,
.login {
  background-image: url("../src/assets/img/app_background.png") !important;
  background-repeat: repeat !important;
}

.fa-spinner {
  font-size: 1.5rem !important;
}

.vertical-center {
  min-width: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.form-control {
  border-radius: 0.3125rem !important;

  &:focus {
    border-color: var(--color-black) !important;
    box-shadow: inset 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.05),
      0 0 0.5rem rgba(160, 160, 160, 0.5) !important;
  }
}

.pointer {
  cursor: pointer !important;
}

.no-select {
  user-select: none;
}

.progress-bar {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid rgba(0, 0, 0, 1);

  b {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
    color: rgba(255, 255, 255, 1);
  }
}

.hidden {
  display: none !important;
}

.bold {
  font-weight: 900;
}

.show {
  visibility: visible !important;
}

.no-color {
  filter: grayscale(1) !important;
}

table {
  text-align: center;
  td {
    white-space: nowrap;
  }
  th {
    text-align: center;
    .fas {
      font-size: 1.2rem;
    }
  }
  .fas {
    font-size: 1.6rem;
  }
}

.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.inactive {
  opacity: 0.5;
}

.center {
  margin: 0 auto;
}

.center-block {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

textarea {
  resize: vertical;
}

.pre-without-format {
  margin: 2rem 0 0 0;
  padding: 0;
  background: none;
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: unset;
  white-space: pre-line;
  word-break: break-word;
}

.home-graph-title {
  font-size: 1.5rem;
  font-weight: bold;

  span {
    display: flex;
    justify-content: center;
    color: gray;
    font-size: 1.8rem;
  }
}

.home-graph-total {
  fill: gray !important;
  font-weight: bold;
}

.mouse-right-click {
  background: -webkit-linear-gradient(0deg, gray 55%, black 45%) 5px no-repeat,
    -webkit-linear-gradient(90deg, gray 50%, gray 50%);
  background: -o-linear-gradient(0deg, gray 55%, black 45%) 5px no-repeat,
    -o-linear-gradient(90deg, gray 50%, gray 50%);
  background: -moz-linear-gradient(0deg, gray 55%, black 45%) 5px no-repeat,
    -moz-linear-gradient(90deg, gray 50%, gray 50%);
  background: linear-gradient(0deg, gray 55%, black 45%) 5px no-repeat,
    linear-gradient(90deg, gray 50%, gray 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.mouse-left-click {
  background: -webkit-linear-gradient(0deg, gray 55%, black 45%) -5px no-repeat,
    -webkit-linear-gradient(90deg, gray 50%, gray 50%);
  background: -o-linear-gradient(0deg, gray 55%, black 45%) -5px no-repeat,
    -o-linear-gradient(90deg, gray 50%, gray 50%);
  background: -moz-linear-gradient(0deg, gray 55%, black 45%) -5px no-repeat,
    -moz-linear-gradient(90deg, gray 50%, gray 50%);
  background: linear-gradient(0deg, gray 55%, black 45%) -5px no-repeat,
    linear-gradient(90deg, gray 50%, gray 50%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.keyboardkey {
  min-width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1px;
  font-size: 1rem;
  // font-weight: bold;
  font-family: Lucida Sans Unicode, Lucida Grande, sans-serif;
  // text-shadow: -0.5px -0.5px 0 #fff, 0.5px -0.5px 0 #fff, -0.5px 0.5px 0 #fff,
  //   0.5px 0.5px 0 #fff;
  padding: 0.2rem 0.4rem;
  line-height: 1.5rem;
  border: 1px solid;
  border-color: #d6d6d6 #b4b4b4 #b4b4b4 #d6d6d6;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #f2f2f2;
}

.image-not-found {
  min-width: 30rem;
  min-height: 40rem;
  display: flex;
  justify-content: center !important;
  align-items: center;

  span {
    margin-left: 1rem;
  }
}

.gateway-icon {
  display: block;
  height: 2rem;
  background-image: url("./assets/img/gateway_icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem 2rem;
}

.gateway-submenu-icon {
  display: block;
  height: 1.75rem;
  background-image: url("./assets/img/gateway_icon_gray.png");
  background-position: 0.1rem center;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.75rem;
}

.gateway-icon-gray {
  width: 1.5rem;
  background-image: url("./assets/img/gateway_icon_gray.png");
  background-size: 1.5rem 1.5rem;
}

.gateway-icon-red {
  width: 1.5rem;
  background-image: url("./assets/img/gateway_icon_red.png");
  background-size: 1.5rem 1.5rem;
}

.arson-submenu-icon {
  display: block;
  height: 2.2rem;
  background-image: url("./assets/img/arson_icon_gray.png");
  background-position: -0.1rem center;
  background-repeat: no-repeat;
  background-size: 2.2rem 2.2rem;
}

.arson-submenu-icon + .sidebar-nav-subicon {
  // right: 0.1rem;
  font-size: 1rem !important;
}

/********************************************************************************************************************/
// ANGULAR MATERIAL
/********************************************************************************************************************/

.cdk-overlay-container {
  z-index: 99999;
}

.mat-button-icon {
  .mat-button-wrapper {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.mat-button-icon-text {
  .mat-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin: 0 1rem 0.3rem 0;
    }
  }
}

.mat-button-red {
  color: red;
}

.mat-button-no-hover .mat-button-focus-overlay {
  background: none !important;
}

.mat-dialog-container {
  padding: 0 !important;
}

.dialog-container {
  position: relative;
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;

  .dialog-spinner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);

    i {
      font-size: 3rem !important;
      margin-bottom: 1rem;
    }

    span {
      font-size: 1.5rem;
    }
  }

  .dialog-header {
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: 1px solid #e5e5e5;
  }

  .dialog-body {
    width: 100%;
    max-height: 80vh;
    overflow: auto;
    padding: 2rem 3rem;

    label {
      font-weight: normal !important;
    }
  }

  .dialog-footer {
    width: 100%;
    padding: 1rem;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: white;
  }
}

.mat-checkbox-inner-container {
  margin-bottom: 5px !important;
}

.material-header-select {
  height: 55px;

  .mat-form-field-underline {
    display: none;
  }
}

.mat-select-arrow-wrapper {
  height: unset !important;
}

.mat-select-value {
  width: 95% !important;
}

.material-header-input {
  height: 55px;
  width: 10rem;

  .mat-form-field-underline {
    display: none;
  }

  .mat-input-element {
    width: 5rem !important;
    text-align: center;
  }
}

.mat-radio {
  margin: 1rem 0;

  label {
    font-weight: normal;
  }
}

.mat-error {
  color: red;

  mat-label {
    color: red;
  }

  mat-hint {
    color: red;
  }

  .mat-form-field-underline::before {
    color: red;
  }
}

.material-menu-separator {
  margin: 5px 0;
}

.material-menu-icon {
  height: unset !important;
}

/********************************************************************************************************************/
// ANCHOR SWEETALERT
/********************************************************************************************************************/

.swal2-container {
  width: unset !important;
}

.swal2-popup {
  font-size: 2rem;

  @media (max-width: 980px) {
    font-size: 1.2rem;
  }
}

.swal2-toast {
  font-size: 1.6rem !important;

  @media (max-width: 980px) {
    font-size: 1.2rem !important;
  }
}

.swal2-popup,
.modal-content {
  border-radius: 0.3125rem !important;
  border: 0.0625rem solid var(--color-black) !important;
}

.swal2-popup {
  .swal2-styled.swal2-cancel,
  .swal2-styled.swal2-confirm {
    border: 0.0625rem solid var(--color-black) !important;
  }
}

.swal2-modal {
  width: unset !important;
  max-width: 40% !important;

  @media (max-width: 980px) {
    max-width: 90% !important;
  }
}

.swal2-input {
  margin-top: 1rem !important;
  min-width: 10rem;
}

.swal2-container {
  z-index: 99999999 !important;
}

.swal2-confirm:hover,
.swal2-deny:hover,
.swal2-cancel:hover {
  box-shadow: 1px 1px 1px gray !important;
}

.swal2-confirm {
  min-width: 8rem;
  margin: 0.5rem 2rem;
  padding: 1rem;
  color: var(--btn-color) !important;
  text-shadow: 0.5px 0.5px 0.5px var(--btn-primary-dark);
  border: 1px solid var(--btn-primary-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-primary-light) 0%,
    var(--btn-primary) 100%
  ) !important;
  box-shadow: 0 -0.25rem 1.5rem var(--btn-primary) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-primary-light) inset !important;
}

.swal2-cancel {
  min-width: 8rem;
  margin: 0.5rem 2rem;
  padding: 1rem;
  color: var(--btn-cancel-color) !important;
  text-shadow: 0.5px 0.5px 0.5px var(--btn-inactive-dark);
  border: 1px solid var(--btn-inactive-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-inactive-light) 0%,
    var(--btn-inactive) 100%
  ) !important;
  box-shadow: 0 -0.25rem 1.5rem var(--btn-inactive) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-inactive-light) inset !important;
}

.swal2-deny {
  min-width: 8rem;
  margin: 0.5rem 2rem;
  padding: 1rem;
  color: var(--btn-color) !important;
  text-shadow: 0.5px 0.5px 0.5px var(--btn-warning-dark);
  border: 1px solid var(--btn-warning-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-warning-light) 0%,
    var(--btn-warning) 100%
  ) !important;
  box-shadow: 0 -0.25rem 1.5rem var(--btn-warning) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-warning-light) inset !important;
}

.swal2-modal {
  font-size: 1.6rem !important;
  line-height: 2rem !important;

  @media (max-width: 980px) {
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
  }
}

.swal2-title {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 2.5rem;
  line-height: 3rem;

  @media (max-width: 980px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

.swal2-actions {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  padding: 0 2rem;
}

.swal2-icon {
  font-size: 1.6rem !important;

  @media (max-width: 980px) {
    font-size: 0.8rem !important;
  }
}

.swal2-alert-with-captcha {
  width: 5rem;
  margin: 0 auto;
  text-align: center;
}

.swal2-html-avoid-margin {
  .swal2-html-container {
    margin: 0 !important;
  }

  .swal2-icon {
    margin-bottom: 5px !important;
  }

  .swal2-title {
    text-align: center !important;
  }
}

/********************************************************************************************************************/
// ANCHOR NG-SELECT
/********************************************************************************************************************/

.ng-select.ng-select-multiple {
  .ng-select-container {
    max-height: 8rem !important;
    overflow-y: auto;

    .ng-value-container {
      .ng-value {
        color: rgba(255, 255, 255, 1);
        background-color: var(--color-black);

        .ng-value-icon.left {
          border: none !important;
          background-color: $marker-red;
        }
      }
    }
  }
}

.ng-toggle-handle {
  background-color: var(--color-white);

  &:hover {
    background-color: var(--table-border-color);
  }
}

.ng-select,
.ng-select-container {
  border-radius: 0.3125rem !important;
}

.modal-open {
  .ng-dropdown-panel {
    z-index: 999999 !important;
  }
}

.select2-selection {
  min-height: 2.25rem;
}

.select2-selection__arrow {
  margin: $radius;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 1.4375rem !important;
}

.select2-container--default .select2-selection--single {
  border: 0.0625rem solid rgba(204, 204, 204, 1);
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0rem !important;
  padding-top: $radius;
}

.select-error {
  .ng-select .ng-select-container {
    border-color: red !important;
  }
}

.timepicker-error {
  .select2-container--default {
    .select2-selection--single {
      border-color: red !important;
    }
  }
}

.select-fix {
  min-width: 10% !important;
}

.ng-select-bottom {
  width: auto !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: var(--color-black) !important;
  box-shadow: inset 0 0.0625rem 0.0625rem rgba(0, 0, 0, 0.05),
    0 0 0.5rem rgba(160, 160, 160, 0.5) !important;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  position: inherit !important;
}

.fixContainer {
  .ng-select.ng-select-multiple {
    .ng-select-container {
      max-height: 1rem !important;
    }
  }
}

/********************************************************************************************************************/
// ANCHOR CONTAINER
/********************************************************************************************************************/

.container-fluid {
  padding: 0;
}

/********************************************************************************************************************/
// ANCHOR PANEL
/********************************************************************************************************************/

.panel-default {
  margin: 0 0 1rem 0;
  border: 0.0625rem solid var(--color-black);
  box-shadow: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.15),
    0 0.375rem 0.375rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

[_nghost-c6] {
  --dial-background-color: var(--color-black) !important;
  --button-color: var(--color-black) !important;
}

// Panel body
.panel-body {
  padding: 2rem;
  cursor: default;
}

// Panel heading
.panel-heading {
  position: relative;
  padding-left: 3rem;
  padding-right: 75px;
  height: 55px;
  display: flex;
  align-items: center;
  cursor: default;

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Source Sans Pro, sans-serif;
  }

  b {
    font-weight: 700;
  }
}

// Panel nav
.panel-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.panel-nav h5 {
  font-weight: bold;
}

.panel-back-link {
  position: absolute;
  top: 0;
  left: 0;
}

/********************************************************************************************************************/
// ANCHOR BOTONES
/********************************************************************************************************************/

.btn {
  border: 0.063rem solid rgba(0, 0, 0, 0.25) !important;
}

.btn-success {
  background-color: $marker-green !important;
}

.btn-info {
  background-color: rgba(100, 181, 246, 1) !important;
}

.btn-danger {
  background-color: $marker-red !important;
}

.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
}

.btn-warning {
  color: var(--btn-color) !important;
  text-shadow: 0.5px 0.5px 0.5px var(--btn-warning-dark);
  border: 1px solid var(--btn-warning-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-warning-light) 0%,
    var(--btn-warning) 100%
  );
  box-shadow: 0 -0.25rem 1.5rem var(--btn-warning) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-warning-light) inset;
}

.btn-primary {
  color: var(--btn-color) !important;
  text-shadow: 0.5px 0.5px 0.5px var(--btn-primary-dark);
  border: 1px solid var(--btn-primary-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-primary-light) 0%,
    var(--btn-primary) 100%
  );
  box-shadow: 0 -0.25rem 1.5rem var(--btn-primary) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-primary-light) inset;
}

.btn:hover {
  filter: brightness(90%);
}

.btn-primary-active {
  color: var(--btn-color) !important;
  text-shadow: 0.5px 0.5px 0.5px var(--btn-active-dark);
  border: 1px solid var(--btn-active-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-active-light) 0%,
    var(--btn-active) 100%
  );
  box-shadow: 0 -0.25rem 1.5rem var(--btn-active) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-active-light) inset;
}

.btn-red {
  color: var(--btn-color) !important;
  text-shadow: 0.5px 0.5px 0.5px var(--btn-red-dark);
  border: 1px solid var(--btn-red-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-red-light) 0%,
    var(--btn-red) 100%
  );
  box-shadow: 0 -0.25rem 1.5rem var(--btn-red) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-red-light) inset;
}

.btn-primary-inactive {
  background-color: var(--btn-inactive);
  color: rgb(177, 177, 177) !important;
  text-shadow: 0.5px 0.5px 0.5px rgb(204, 204, 204);
  border: 1px solid var(--btn-inactive-dark) !important;
  box-shadow: inset 0 0 5px var(--btn-inactive-dark);
}

.btn-primary-inactive:hover {
  color: var(--btn-inactive-color) !important;
  text-shadow: 0.5px 0.5px 0.5px gray;
  border: 1px solid var(--btn-inactive-dark) !important;
  background-image: linear-gradient(
    -180deg,
    var(--btn-inactive-light) 0%,
    var(--btn-inactive) 100%
  );
  box-shadow: 0 -0.25rem 1.5rem var(--btn-inactive) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 var(--btn-inactive-light) inset;
}

.btn-primary-disabled {
  opacity: 0.6;
  cursor: default !important;
}

.btn-primary-disabled:hover {
  filter: none !important;
}

/********************************************************************************************************************/
// ANCHOR FILE INPUT
/********************************************************************************************************************/

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn.btn-file > input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  opacity: 0;
  filter: alpha(opacity=0);
  outline: none;
  background: #fff;
  cursor: inherit;
  display: block;
}

/********************************************************************************************************************/
// ANCHOR SELECTOR DE FECHAS
/********************************************************************************************************************/

ngx-daterangepicker-material {
  z-index: 9999 !important;
  overflow: auto !important;
}

.md-drppicker {
  max-height: 30rem;
  overflow: auto !important;
}

/********************************************************************************************************************/
// ANCHOR HIGHCHARTS
/********************************************************************************************************************/

highcharts-chart {
  width: 100%;
  display: block;
}

.highcharts-root {
  font-size: 1.5rem !important;
}

.highcharts-markers {
  cursor: pointer;
}

.highcharts-menu {
  max-height: 30rem;
  overflow: auto;
}

.highcharts-chart-heatmap {
  .highcharts-axis-title {
    font-weight: bold;
    text-transform: capitalize;
  }
}

.highcharts-tooltip-container {
  z-index: 99999 !important;
}

.highcharts-contextmenu {
  font-size: 1.4rem !important;
}

/********************************************************************************************************************/
// ANCHOR SPINNER
/********************************************************************************************************************/

// Spinner de carga
.loading-spinner {
  width: 100%;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > i {
    font-size: 2rem !important;
  }

  > span {
    margin-top: 2rem;
  }
}

.loading-text i {
  color: white !important;
}

/********************************************************************************************************************/
// ANCHOR TABLAS
/********************************************************************************************************************/

// Gradientes de tablas
.table-bg-red-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    lighten(#c92c26, 5%),
    #c92c26,
    lighten(#c92c26, 5%)
  ) !important;
}

.table-bg-green-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    lighten(#278127, 5%),
    #277e27,
    lighten(#278127, 5%)
  ) !important;
}

.table-bg-blue-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    lighten(#258cac, 5%),
    #258cac,
    lighten(#258cac, 5%)
  ) !important;
}

// Tabla de importación de gateways
.gateways-file-table {
  width: 100%;
  text-align: left;
}

.gateways-file-table-required {
  font-weight: bold;
}

.gateways-file-table th {
  padding: 0.5rem;
  text-align: left;
  min-width: 15rem;
}

.gateways-file-table td {
  padding: 0.5rem;
  white-space: normal;
}

.request-table-spinner {
  padding: 0 !important;
}

.table-meter-list {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;

  span {
    display: block;
  }
}

/********************************************************************************************************************/
// ANCHOR MAPAS: CESIUM
/********************************************************************************************************************/

.cesium-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.cesium-map-tooltip {
  position: absolute;
  bottom: calc(20px + 6rem);
  left: 10px;
  font-size: 1.2rem;
  background-color: white;
  border-radius: 12px;
  border: 1px solid #747474;
  box-shadow: 2px 2px 2px black;
}

.cesium-map-agrupation-tooltip {
  span:first-of-type {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  table {
    margin: 1rem;

    th {
      margin-right: 1rem;
      text-align: left;
    }

    td {
      padding-left: 1rem;
      text-align: right;
    }
  }
}

.cesium-viewer-bottom {
  display: none !important;
}

/********************************************************************************************************************/
// ANCHOR MAPAS: LEAFLET
/********************************************************************************************************************/

.leaflet-geosearch-bar,
.leaflet-geosearch-button {
  font-size: 1.2rem !important;
}

.icon:active ~ .legend {
  display: block;
}

.map-legend {
  z-index: 9999;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  color: white;
  font-size: 1.8rem;
  background: black;
  border: 2px solid white;
  border-radius: 3rem;
  box-shadow: 2px 2px 2px rgb(155, 155, 155);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}

.legend:hover {
  display: block;
}

.legend {
  display: none;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  cursor: default;

  ul {
    padding: 0.5rem 0.5rem 0 0.5rem;
    list-style: none;

    li {
      margin: 0.5rem 0.5rem 0 0.5rem;
      pointer-events: none;
      display: flex;
      align-items: center;
      font-size: 1.2rem;

      > i {
        position: relative;
        width: 2rem;
        margin-right: 0.5rem;
        display: flex;
        justify-content: center;

        .icon-extra {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    img {
      width: 2rem;
      height: 2rem;
    }
  }
}

.leaflet-bottom {
  z-index: 9999 !important;
}

.leaflet-bottom.leaflet-right {
  z-index: 999 !important;
}

.leaflet-popup-content {
  font-size: 1.2rem;

  .to {
    color: rgba(0, 0, 0, 0.5) !important;

    &:hover {
      color: rgba(0, 0, 0, 1) !important;
    }
  }

  ul {
    list-style: none;
  }

  .fixPadding {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.red {
  color: $marker-red !important;
}

.rotated90 {
  transform: rotate(-90deg);
}

.leaflet-control-layers {
  font-size: 1.2rem;
}

.map-tooltip-mouse-info {
  margin-top: 0.5rem;
  color: gray;

  div {
    display: flex;
    align-items: center;
  }

  span {
    margin: 0 0 0 1rem !important;
    padding: 0 !important;
  }
}

/********************************************************************************************************************/
// ANCHOR MAPAS: MARCADORES
/********************************************************************************************************************/

.markers {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 1);

  &:hover {
    filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.25));
  }
}

.fa-lg {
  font-size: calc(1.5rem * var(--map-icon-size));

  &:hover {
    font-size: 1.6rem !important;
  }
}

.deviceNoLoraNoCom {
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .fa-times {
    color: white !important;
  }
}

.concentrator-icon {
  display: block;
  position: absolute;
  top: calc(8px * var(--map-icon-size));
  font-weight: 900;
  font-size: calc(9px * var(--map-icon-size));
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke: 0.1px white;
}

.greenIcon,
.grayIcon,
.redIcon,
.purpleIcon,
.orangeIcon,
.turquoiseIcon,
.yellowIcon {
  .fa-tint.fa-2x {
    width: calc(35px * var(--map-icon-size));
    height: calc(35px * var(--map-icon-size));
    margin-top: calc(-14px * var(--map-icon-size));
    margin-left: calc(-14px * var(--map-icon-size));
    padding-bottom: calc(10px * var(--map-icon-size));
    display: flex;
    justify-content: center;
    align-items: center;
    background: $marker-cluster-lightGreen;
    // background: none !important;
    border-radius: 50%;
    font-size: calc(1.5rem * var(--map-icon-size));
  }
}

.grayIcon {
  .fa-tint.fa-2x {
    background: $marker-cluster-lightGray;
  }
}

.redIcon {
  .fa-tint.fa-2x {
    background: $marker-cluster-lightRed;
  }
}

.purpleIcon {
  .fa-tint.fa-2x {
    background: $marker-cluster-lightPurple;
  }
}

.orangeIcon {
  .fa-tint.fa-2x {
    background: $marker-cluster-lightOrange;
  }
}

.turquoiseIcon {
  .fa-tint.fa-2x {
    background: $marker-cluster-lightTurquoise;
  }
}

.yellowIcon {
  .fa-tint.fa-2x {
    background: $marker-cluster-lightYellow;
  }
}

.locationIcon {
  margin-top: calc(-10px * var(--map-icon-size)) !important;
  margin-left: calc(-10px * var(--map-icon-size)) !important;
  padding: calc(10px * var(--map-icon-size));
  font-size: calc(5px * var(--map-icon-size));
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  border: 1px solid black;
}

.marker-cluster-lw-une {
  z-index: 1200 !important;

  i {
    margin-right: 2px;
    margin-left: -2px;
  }
}

.opacityIcon {
  opacity: 0.5;
}

.iconSelected {
  @extend .markers;
  font-size: 1.5rem;
}

.whiteIcon {
  @extend .markers;
  color: white;
}

.redIcon {
  @extend .markers;
  color: $marker-red;
}

.greenIcon {
  @extend .markers;
  color: $marker-green;
}

.yellowIcon {
  @extend .markers;
  color: $marker-yellow;
}

.yellowGreenIcon {
  @extend .markers;
  color: $marker-limeGreen;
}

.blueIcon {
  @extend .markers;
  color: $marker-blue;
}

.cadetblueIcon {
  @extend .markers;
  color: $marker-cadetblue;
}

.purpleIcon {
  @extend .markers;
  color: $marker-purple;
}

.orangeIcon {
  @extend .markers;
  color: $marker-orange;
}

.turquoiseIcon {
  @extend .markers;
  color: $marker-turquoise;
}

.grayIcon {
  @extend .markers;
  color: $marker-gray;
}

.rangeIcon {
  @extend .markers;
  color: $marker-blue;
}

.extraRangeIcon {
  @extend .markers;
  color: $marker-cluster-lightCadetblue;
}

.arson {
  @extend .markers;
  width: calc(4rem * var(--map-icon-size)) !important;
  filter: drop-shadow(1px 1px black);

  &:hover {
    width: calc(4.5rem * var(--map-icon-size)) !important;
  }
}

.arsonMax {
  @extend .markers;
  width: calc(6rem * var(--map-icon-size)) !important;
  filter: drop-shadow(1px 1px black);

  &:hover {
    width: calc(6.5rem * var(--map-icon-size)) !important;
  }
}

// .arson:focus {
//   border-radius: 100px;
//   border: 1px solid black;
//   background-color: white;
// }

.bounce {
  position: relative;
  animation: bounce 1s infinite ease-in-out;
}

@keyframes bounce {
  0% {
    top: 0;
  }

  50% {
    top: -0.3rem;
  }

  70% {
    top: -0.3rem;
  }

  100% {
    top: 0;
  }
}

.flowSensor {
  margin-left: -25px !important;
  margin-top: -12px !important;
  width: 50px !important;
  height: unset !important;
  background: linear-gradient(Gainsboro, Gainsboro);
  background-size: 100% 50%;
  background-position: 0px 50%;
  background-repeat: no-repeat;
}

.flow-sensor-pipe {
  background-size: 100% 50%;
  background-position: 0px 50%;
  background-repeat: no-repeat;

  img {
    width: 50px !important;
    height: auto;
  }
}

/********************************************************************************************************************/
// ANCHOR MAPAS: CLÚSTER
/********************************************************************************************************************/

// Default
.marker-cluster-default {
  background-clip: padding-box;
  border-radius: 20px;
  color: white;
  margin-left: calc(-20px * var(--map-icon-size)) !important;
  margin-top: calc(-20px * var(--map-icon-size)) !important;
  width: calc(40px * var(--map-icon-size)) !important;
  height: calc(40px * var(--map-icon-size)) !important;
}

.marker-cluster-default div {
  width: calc(30px * var(--map-icon-size));
  height: calc(30px * var(--map-icon-size));
  margin-left: calc(5px * var(--map-icon-size));
  margin-top: calc(5px * var(--map-icon-size));
  text-align: center;
  border-radius: calc(15px * var(--map-icon-size));
}

.marker-cluster-point {
  background-clip: padding-box;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: calc(30px * var(--map-icon-size));
      height: calc(30px * var(--map-icon-size));
      text-align: center;
      border-radius: calc(15px * var(--map-icon-size));

      span {
        font-size: calc(14px * var(--map-icon-size));
        line-height: calc(30px * var(--map-icon-size));
      }
    }
  }
}

.marker-cluster-default span {
  font-size: calc(14px * var(--map-icon-size));
  line-height: calc(30px * var(--map-icon-size));
}

.marker-cluster-green {
  background-color: $marker-cluster-lightGreen;
  z-index: 1000 !important;

  div {
    background-color: $marker-green;
  }
}

.marker-cluster-green-concentrator {
  background-color: $marker-cluster-lightGreen;
  z-index: 1200 !important;

  div {
    background-color: $marker-green;
  }
  i {
    margin: 0 2px 0 -2px;
  }
}

.marker-cluster-red-concentrator {
  background-color: $marker-cluster-lightGreen;
  z-index: 1200 !important;

  div {
    background-color: $marker-red;
  }
  i {
    margin: 0 2px 0 -2px;
  }
}

.marker-cluster-gray-concentrator {
  background-color: $marker-cluster-lightGray;
  z-index: 1200 !important;

  div {
    background-color: $marker-gray;
  }
  i {
    margin: 0 2px 0 -2px;
  }
}

.marker-cluster-red {
  background-color: $marker-cluster-lightRed;
  z-index: 600 !important;

  div {
    background-color: $marker-red;
  }
}

.marker-cluster-orange {
  background-color: $marker-cluster-lightOrange;
  z-index: 600 !important;

  div {
    background-color: $marker-orange;
  }
}

.marker-cluster-red-alarm {
  background-color: $marker-cluster-lightRed;
  z-index: 2000 !important;

  div {
    background-color: $marker-red;
  }
}

.marker-cluster-red-concentrator {
  background-color: $marker-cluster-lightRed;
  z-index: 700 !important;

  div {
    background-color: $marker-red;
  }
  i {
    margin: 0 2px 0 -2px;
  }
}

.marker-cluster-purple {
  background-color: $marker-cluster-lightPurple;
  z-index: 700 !important;

  div {
    background-color: $marker-purple;
  }
}

.marker-cluster-purple-concentrator {
  background-color: $marker-cluster-lightPurple;
  z-index: 800 !important;

  div {
    background-color: $marker-purple;
  }
  i {
    margin: 0 2px 0 -2px;
  }
}

.marker-cluster-blue {
  background-color: $marker-cluster-lightBlue;
  z-index: 900 !important;

  div {
    background-color: $marker-blue;
  }
}

.marker-cluster-cadetblue {
  background-color: $marker-cluster-lightCadetblue;
  z-index: 900 !important;

  div {
    background-color: $marker-cadetblue;
  }
}

.marker-cluster-turquoise {
  background-color: $marker-cluster-lightBlue;
  z-index: 800 !important;

  div {
    background-color: $marker-turquoise;
  }
}

.marker-cluster-gateway {
  background-color: $marker-cluster-lightWhite;
  z-index: 1500 !important;

  div {
    background-color: $marker-cluster-gateway;
  }
}

.marker-cluster-yellow {
  background-color: $marker-cluster-lightYellow;
  z-index: 1000 !important;

  div {
    background-color: $marker-gradient-yellow;
  }
}

.marker-cluster-gray {
  background-color: $marker-cluster-lightGray;
  z-index: 600 !important;

  div {
    background-color: $marker-gray;
  }
}

.marker-cluster-mix {
  background-color: $marker-cluster-lightGray;
  z-index: 1500 !important;
}

.clip-star {
  margin: 0 !important;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
  width: 40px !important;
}

/********************************************************************************************************************/
// ANCHOR MAPAS: TOOLTIP
/********************************************************************************************************************/

.ok-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.concentratorok-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.uneok-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.ekok-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.sigfoxok-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.erm-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-turquoise,
    $marker-gradient-turquoise,
    lighten($marker-gradient-turquoise, 5%)
  ) !important;
}

.noasignado-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-purple,
    $marker-gradient-purple,
    lighten($marker-gradient-purple, 5%)
  ) !important;
}

.concentratornoasignado-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-purple,
    $marker-gradient-purple,
    lighten($marker-gradient-purple, 5%)
  ) !important;
}

.nocomunica-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.concentratornocomunica-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.unenocomunica-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.eknocomunica-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.sigfoxnocomunica-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.nolora-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-turquoise,
    $marker-gradient-turquoise,
    lighten($marker-gradient-turquoise, 5%)
  ) !important;
}

.noloranocom-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-gray,
    $marker-gradient-gray,
    lighten($marker-gradient-gray, 5%)
  ) !important;
}

.mbusmainnocomunica-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-gray,
    $marker-gradient-gray,
    lighten($marker-gradient-gray, 5%)
  ) !important;
}

.nolora-ok-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.nolora-fail-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.mbus-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.mbuscomunica-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-cadetblue,
    $marker-gradient-cadetblue,
    lighten($marker-gradient-cadetblue, 10%)
  ) !important;
}

.nbiot-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-brown,
    $marker-gradient-brown,
    lighten($marker-gradient-brown, 10%)
  ) !important;
}

.wavenis-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.coverageok-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.coverageerror-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.controlvalvemeter-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.controlalertassigned-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.controlalertopen-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.controlsensor-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-purple,
    $marker-gradient-purple,
    lighten($marker-gradient-purple, 5%)
  ) !important;
}

.meterdetail-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.cupsdetail-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.meterlocation-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.selected-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-yellow,
    $marker-gradient-yellow,
    lighten($marker-gradient-yellow, 10%)
  ) !important;
}

.error-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.gateway-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.gatewayerror-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 5%)
  ) !important;
}

.gatewaywarning-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-yellow,
    $marker-gradient-yellow,
    lighten($marker-gradient-yellow, 5%)
  ) !important;
}

.gatewaymaintenance-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-orange,
    $marker-gradient-orange,
    lighten($marker-gradient-orange, 5%)
  ) !important;
}

.father-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.child-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.substitutionsreplaced-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.substitutionspending-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-blue,
    $marker-gradient-blue,
    lighten($marker-gradient-blue, 10%)
  ) !important;
}

.meterlist-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-turquoise,
    $marker-gradient-turquoise,
    lighten($marker-gradient-turquoise, 5%)
  ) !important;
}

.mbusconcentrator-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.gatewaydetailassignablemeter-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-brown,
    $marker-gradient-brown,
    lighten($marker-gradient-brown, 5%)
  ) !important;
}

.alarmsmeterlist-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-yellow,
    $marker-gradient-yellow,
    lighten($marker-gradient-yellow, 10%)
  ) !important;
}

.alarmsmeterlistnew-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-red,
    $marker-gradient-red,
    lighten($marker-gradient-red, 10%)
  ) !important;
}

.gatewaydetailmainmeter-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-green,
    $marker-gradient-green,
    lighten($marker-gradient-green, 5%)
  ) !important;
}

.gatewaydetailredundantmeter-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-turquoise,
    $marker-gradient-turquoise,
    lighten($marker-gradient-turquoise, 5%)
  ) !important;
}

.gatewaylocation-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: linear-gradient(
    to right,
    $marker-gradient-gray,
    $marker-gradient-gray,
    lighten($marker-gradient-gray, 5%)
  ) !important;
}

.cups-gradient {
  text-shadow: 0.5px 0.5px 0.5px black;
  background: rgb(73, 73, 73);
  border-bottom: 1px solid white;
}

.map-link {
  text-decoration: none;
  color: unset !important;

  i {
    margin: 0 0.5rem;
    font-size: 1rem !important;
  }
}

.map-separator-tr {
  height: 1rem;
}

.map-separator-tr-withCUPS {
  height: 1rem;
}

.map-scroll-list {
  max-height: 5rem;
  overflow: auto;
}

.map-element-list ul {
  margin: 0;
  padding: 0;
}

.map-element-list ul > li {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.map-element-list ul > li > div {
  width: 50%;
}

.map-list-title {
  display: flex;
  padding-top: 0.5rem !important;
}

.map-title {
  font-size: 1.2rem;
}

.map-tooltip-control th i {
  margin-left: 2rem;
}

.map-tooltip-close-background {
  position: fixed;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: black;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 8rem;
}

.map-tooltip {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  cursor: default;

  table {
    min-width: 25rem;
    margin-bottom: 1rem;
  }

  table th {
    width: 100%;
    padding: 1rem 3rem 1rem 1rem;
    font-size: 1.3rem;
    text-align: left;
    color: white !important;
  }

  .map-tooltip-title {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }

  .map-tooltip-title-withCUPS {
    top: 3rem;
    left: 0;
    width: 100%;
  }

  table tr:first-of-type th {
    padding-bottom: 0;
  }

  table th i {
    margin-left: 0.5rem;
    font-size: 1.2rem;
  }

  table td {
    padding: 0.2rem 1rem;
    text-align: left;
    // vertical-align: top;
  }

  table td ul {
    margin: 0;
    padding: 0;
  }

  .fa-link {
    font-size: 1.2rem;
  }

  .map-show-alert {
    margin: 1rem auto 0.5rem auto;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      margin-right: 1rem;
      font-size: 1.2rem;
    }
  }

  .photoModal,
  .filterGateway,
  .photoModal,
  .filterConcentrator {
    button {
      margin-top: 0.5rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
    }

    i {
      margin-right: 1rem;
    }
  }
}

.location-tooltip {
  padding: 0 0.5rem;
  font-size: 1.2rem;
  display: block;

  table {
    text-align: left;
    margin: 0;
    padding: 0;

    tr {
      display: flex;
      justify-content: space-between;

      td:last-of-type {
        margin-left: 2rem;
        text-align: right;
      }
    }

    td {
      display: block;
      margin: 0.2rem 0;
    }
  }
}

.leaflet-tooltip:has(.location-tooltip) {
  pointer-events: auto;
}

.leaflet-tooltip:has(.location-tooltip:hover) {
  z-index: 99;
}

.map-gateway-repair {
  background-size: 16px 16px !important;
  background: url(./assets/img/repair.png) no-repeat;
  background-position: right bottom;
  animation-name: map-gateway-maintenance-bounce;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

.map-gateway-broken {
  background-size: 16px 16px !important;
  background: url(./assets/img/broken.png) no-repeat;
  background-position: right bottom;
  animation-name: map-gateway-maintenance-bounce;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

.map-gateway-maintenance {
  background-size: 16px 16px !important;
  background: url(./assets/img/reset.png) no-repeat;
  background-position: right bottom;
  animation-name: map-gateway-maintenance-bounce;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

@keyframes map-gateway-maintenance-bounce {
  0% {
    background-position: 100% 100%;
  }
  50% {
    background-position: 100% 95%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.map-device-substitution {
  position: absolute;
  top: 0;
  left: 4px;
  width: 20px;
  height: 20px;
  background-size: 20px 20px !important;
  background: url(./assets/img/replace.png) no-repeat;
  background-position: center center;
  pointer-events: none;
}

.map-agrupation-tooltip {
  font-size: 1.2rem;

  span:first-of-type {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
  }

  table {
    th {
      margin-right: 1rem;
      text-align: left;
    }

    td {
      padding-left: 1rem;
      text-align: right;
    }
  }
}

.map-coverage-tooltip {
  font-weight: bold;
}

.map-tooltip-flags {
  i {
    margin-right: 0.5rem;
    text-shadow: 1px 0 0 black, -1px 0 0 black, 0 1px 0 black, 0 -1px 0 black,
      1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black;
  }

  i:last-of-type {
    margin-right: 0;
  }
}

.map-device-location-error {
  width: 100%;
  max-height: 5rem;
  padding: 1rem;
  overflow: auto;
  text-align: justify;
  margin-bottom: 1rem;
}

.leaflet-tooltip:has(.pressure-tooltip) {
  margin-left: 1.5px;
  padding: 2px 3px;
  pointer-events: auto;
}

.leaflet-tooltip:has(.pressure-warning-tooltip) {
  margin-left: 0;
}

.leaflet-tooltip:has(.pressure-tooltip:hover) {
  z-index: 99;
}

.pressure-tooltip {
  color: gray;
  font-weight: bold;
}

.pressure-warning-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    margin: 0 0.3rem 0.2rem 0;
    color: yellow;
    -webkit-text-stroke: 0.8px black;
  }
}

.warningSensor {
  margin-left: -10px !important;
  background-color: white;
  background-clip: text;
  -webkit-background-clip: text;
}

/********************************************************************************************************************/
// ANCHOR MODAL
/********************************************************************************************************************/

.modal {
  z-index: 999999;
  text-align: center;
  padding: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.015625rem;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.modal-body {
  max-width: 100%;
  max-height: 75vh;
  overflow: auto;
}

.modal-backdrop {
  background: none;
}

/********************************************************************************************************************/
// ANCHOR SATELITE: PANEL DE TIEMPO
/********************************************************************************************************************/

.satelite-schedule-turn-slider-first {
  border-right: none !important;

  .schedule-turn-slider-end {
    display: none;
  }
}

.satelite-schedule-turn-slider-second {
  border-left: none !important;
}

.schedule-turn-hide-pulses .schedule-turn-slider-pulses {
  display: none !important;
}

.schedule-turn-slider-start-left .schedule-turn-slider-start {
  left: -2px;
  border-left: 1px solid black;

  input {
    margin-left: -1px;
  }
}

.schedule-turn-slider-start-right .schedule-turn-slider-start {
  right: 2px;
  border-right: 1px solid black;

  input {
    margin-right: -1px;
  }
}

.schedule-turn-slider-end-left .schedule-turn-slider-end {
  left: 2px;
  border-left: 1px solid black;

  input {
    margin-left: -1px;
  }
}

.schedule-turn-slider-end-right .schedule-turn-slider-end {
  right: -2px;
  border-right: 1px solid black;

  input {
    margin-right: -1px;
  }
}

/********************************************************************************************************************/
// ANCHOR LISTA ANIDADA
/********************************************************************************************************************/

.nested-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  ul {
    margin: 0;
    padding: 0 0 0 2rem;
  }

  li {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .list-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .list-title {
    cursor: pointer;
  }

  /* Style the caret/arrow */
  .list-caret {
    margin: 0 0.5rem 0 0;
    font-size: 1.2rem;
    cursor: pointer;
    user-select: none; /* Prevent text selection */
  }

  /* Rotate the caret/arrow icon when clicked on (using JavaScript) */
  .list-caret-down {
    transform: rotate(90deg);
  }

  /* Hide the nested list */
  .nested {
    display: none;
  }

  /* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
  .active {
    display: block;
  }
}

/********************************************************************************************************************/
// ANCHOR HELP
/********************************************************************************************************************/

.help-tooltip {
  text-align: center;
  position: fixed;
  z-index: 999999;
  padding: 6px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  width: auto;
  background: white;
  box-sizing: border-box;
  opacity: 0;
  transform: translate(-50%, -30%);
  animation: tooltip-slide 0.18s ease-out;
  animation-fill-mode: forwards;
  pointer-events: none;
  border: 1px solid RoyalBlue;
  box-shadow: 1px 1px solid black;

  div {
    display: flex;
    flex-direction: column;
  }

  i {
    font-size: 1.5rem;
    line-height: 1.5;
    color: RoyalBlue;
  }

  span {
    line-height: 1.5;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid RoyalBlue;
    position: absolute;
    left: calc(50% - 5px);
    top: -5px;
  }
}

@keyframes tooltip-slide {
  0% {
    opacity: 0;
    transform: translate(-50%, -30%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

/********************************************************************************************************************/
// ANCHOR RESPONSIVE
/********************************************************************************************************************/

@media (max-width: 980px) {
  .content-header {
    padding: 1rem 0.5rem;
  }

  .container-fluid {
    padding: 0;
  }

  .panel-body {
    padding: 1rem;
  }

  .modal-dialog {
    max-width: 90%;
  }

  .modal-body {
    max-height: unset;
  }

  .panel-heading {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }

  .map-modal-img {
    max-width: 100%;
    height: auto;
  }

  .gateway-submenu-icon {
    width: 1.6rem;
    height: 1.75rem;
    background-image: url("./assets/img/gateway_icon.png");
    background-position: center center;
    background-size: 1.35rem 1.55rem;
  }

  .gateway-icon {
    width: 3rem;
    height: 3rem;
    background-size: 3rem 3rem;
  }
}
